import { useState } from "react";
import useWindowWidth from "../hooks/use-window-width";
import { useEffect, useRef } from "react";

export const useChildMaxHeight = () => {
  const vpWidth = useWindowWidth();
  const [height, setHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    const maxH = nodes =>
      Math.max(...Array.from(nodes).map(c => c.clientHeight));

    ref.current && setHeight(maxH(ref.current.childNodes));
  }, [vpWidth]);

  return [height, ref];
};

export default useChildMaxHeight;
