import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import HowToCompete from "app/templates/how-to-compete";

const HowToCompetePage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "how-to-compete" }) {
        ...HowToCompetePage
      }
    }
  `);

  return <HowToCompete {..._get(data, "contentJson")} />;
};

export default HowToCompetePage;
