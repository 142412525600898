import { useState } from "react";
import { useSwipeable } from "react-swipeable";
// import { useEffect, useRef } from "react";

import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem } from "../styles/utils";

export const useDetectInputType = (starting = "touch") => {
  const [ctrlType, setCtrlType] = useState(starting);

  const handlers = {
    onMouseEnter: () => setCtrlType("mouse"),
    onTouchStart: () => setCtrlType("touch"),
  };

  return { handlers, ctrlType };
};

export const useSwipeTrack = ({ goNext, goPrev }) => {
  const [delta, setDelta] = useState();
  const onSwiping = ({ deltaX }) => setDelta(deltaX);
  const onSwiped = ({ dir }) => {
    if (dir === "Right") {
      setDelta(0);
      goPrev();
    }
    if (dir === "Left") {
      setDelta(0);
      goNext();
    }
  };
  const handlers = useSwipeable({ onSwiping, onSwiped });

  return { handlers, delta };
};

export default useSwipeTrack;

export const dragTrackStyle = ({ delta }) => css`
  transform: translateX(${rem(delta * -1)}) translateZ(0);
`;

export const DragTrack = styled.div`
  ${dragTrackStyle};
`;
