import React, { memo } from "react";
import Helmet from "react-helmet";
import Btn from "./btn";
import ShareIcon from "./icons/share-icon";

// import * as S from "./share-button.styles";

const shareServiceUrl = `https://static.addtoany.com/menu/page.js`;

const ShareButton = ({ config, className, text, icon, ...props }) => (
  <>
    <Helmet>
      <script async="async" src={shareServiceUrl} />
    </Helmet>
    <Btn
      {...props}
      href="https://www.addtoany.com/share"
      as="button"
      className={[className, "a2a_dd"].filter((c) => c).join(" ")}
    >
      {icon && <ShareIcon width={50} height={50} />}
      {text}
    </Btn>
  </>
);

ShareButton.defaultProps = {
  config: {},
  icon: true,
  text: "Share This",
};

export default memo(ShareButton);
