import React, { useState } from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import { useSwipeTrack } from "app/hooks/use-swipe-track";
import { useChildMaxHeight } from "app/hooks/use-child-max-height";
import { useTimelineData, useActivePhase } from "app/hooks/use-timeline-data";

import VideoBG from "./video-background";
import markerBG from "../../static/videos/timeline.mp4";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/timeline.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const ActiveCard = ({ text, ...props }) => {
  const date = new Date(_get(props, "date"));
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  return (
    <S.TlnCard as="figure" {...props}>
      <S.TlnCardDate as="h3">
        {month} {day}
      </S.TlnCardDate>
      <S.TlnCardTxt>{text}</S.TlnCardTxt>
    </S.TlnCard>
  );
};

const TimelineControl = ({ phases, setActive, active }) => {
  const goPrev = () => active > 0 && setActive(active - 1);
  const goNext = () => active < phases.length - 1 && setActive(active + 1);
  const { delta, handlers } = useSwipeTrack({ goPrev, goNext });

  return (
    <S.TlnCrlViewbox>
      <S.TlnWrap>
        <S.ActiveMarker active={active || 0}>
          <VideoBG circle src={markerBG} css={S.activeBg} />
        </S.ActiveMarker>
        <S.TlnCrlList
          {...handlers}
          active={active || 0}
          delta={delta || 0}
          as="div"
        >
          {phases.map(({ label }, idx) => (
            <S.TlnCrlItem key={`tl-${idx}`} as="figure">
              <S.TlnBtnWrap
                active={active === idx}
                first={idx === 0}
                last={idx === phases.length - 1}
              >
                <S.TlnBtn
                  onClick={() => setActive(idx)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                >
                  {label}
                </S.TlnBtn>
              </S.TlnBtnWrap>
            </S.TlnCrlItem>
          ))}
        </S.TlnCrlList>
      </S.TlnWrap>
    </S.TlnCrlViewbox>
  );
};

TimelineControl.defaultProps = {
  phases: [],
};

const Timeline = ({ timeline, current }) => {
  const [cardH, listRef] = useChildMaxHeight();
  const [titleH, titlesRef] = useChildMaxHeight();
  const last = timeline.length - 1;
  const start = current > -1 ? current : last;

  const [active, setActive] = useState(start);

  return (
    <S.TlnBox as="section">
      <div ref={titlesRef}>
        {timeline.map(({ title }, idx) => (
          <S.TlnTitle
            minH={titleH}
            active={active === idx}
            idx={idx}
            key={idx}
            as="h2"
          >
            {title}
          </S.TlnTitle>
        ))}
      </div>

      <TimelineControl
        phases={timeline}
        setActive={setActive}
        active={active}
      />
      <S.Container ref={listRef}>
        {timeline.map((item, idx) => (
          <ActiveCard
            key={idx}
            minH={cardH}
            active={active === idx}
            {...item}
          />
        ))}
      </S.Container>
    </S.TlnBox>
  );
};

const TimelineWithData = (props) => {
  const activePhase = useActivePhase();
  const timeline = useTimelineData();

  return (
    <Timeline
      timeline={timeline}
      current={timeline.indexOf(activePhase)}
      {...props}
    />
  );
};

export default TimelineWithData;

export const timelineFragment = graphql`
  fragment Timeline on ContentJson {
    timeline {
      label
      date
      title
      text
      countdownTitle
    }
  }
`;

export const timelineField = {
  label: "Timeline",
  name: "timeline",
  widget: "list",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Date",
      name: "date",
      widget: "datetimeLocal",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Text",
      name: "text",
      widget: "text",
    },
    {
      label: "Countdown Title",
      name: "countdownTitle",
      widget: "string",
    },
  ],
};
