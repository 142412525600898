import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import useEndDate from "app/hooks/use-end-date";

const GET_TIMELINE_DATA = graphql`
  query {
    contentJson(template: { eq: "how-to-compete" }) {
      ...Timeline
    }
  }
`;

export const useTimelineData = () => {
  const data = useStaticQuery(GET_TIMELINE_DATA);
  return _get(data, "contentJson.timeline") || [];
};

export default useTimelineData;

export const useActivePhase = () => {
  const { today } = useEndDate();
  const timeline = useTimelineData();

  return timeline.find(({ date }) => date >= today);
};
