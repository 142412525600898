import React from "react";
import { graphql } from "gatsby";
import { useWindowSize } from "react-use";
import _chunk from "lodash.chunk";

import Carousel from "./carousel";
import { DoubleTile } from "./tile";

import { mobilepx as mobileBreak } from "../styles/breakpoints";

const PastWinners = ({ winners, title }) => {
  const { width } = useWindowSize();
  const sets = _chunk(winners, 2).map((chunk) => ({
    winners: chunk,
  }));

  return width > mobileBreak ? (
    <Carousel
      headline={title}
      items={sets}
      Tile={<DoubleTile />}
      width={"100vw"}
      accent={true}
    />
  ) : (
    <Carousel headline={title} items={winners} accent={true} />
  );
};

export default PastWinners;

export const pastWinnersFragment = graphql`
  fragment PastWinners on ContentJson {
    pastWinners {
      title
      winners {
        ...WinnerTile
      }
    }
  }
`;

export const pastWinnersField = {
  label: "Past Winners",
  name: "pastWinners",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Winners",
      name: "winners",
      widget: "list",
      fields: [
        {
          label: "Name",
          name: "name",
          widget: "string",
        },
        {
          label: "Desc",
          name: "text",
          widget: "text",
        },
        {
          label: "Image",
          name: "img",
          widget: "image",
        },
        {
          label: "SDG",
          name: "color",
          multiple: false,
          widget: "relation",
          collection: "sdgs",
          searchFields: ["sdg_name", "sdg_id"],
          valueField: "color",
          displayFields: ["sdg_id"],
        },
      ],
    },
  ],
};
