import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import IntroBlock from "app/components/intro-block";
import HeroBar, { heroBarField } from "app/components/hero-bar";
import GetStarted, { getStartedField } from "app/components/get-started";
import Layout from "app/components/layout";
import Steps, { stepsField } from "app/components/steps";
import Timeline, { timelineField } from "app/components/timeline";
import Countdown from "app/components/countdown";
import SEOMeta, { seoField } from "app/components/seo";
import StatList, { whoCanEnterField } from "app/components/stat-list";
import PastWinners, { pastWinnersField } from "app/components/past-winners";

import * as sharedStyles from "app/styles/shared";
import * as pageStyles from "app/components/page.styles";

const S = { ...sharedStyles, ...pageStyles };

const HowToCompete = ({
  awards,
  getStarted1,
  getStarted2,
  intro,
  pastWinners,
  timeline,
  title,
  steps,
  whoCanEnter,
  ...props
}) => (
  <Layout>
    <SEOMeta title={title} {..._get(props, "seo")} />
    <article>
      <S.PageBody>
        <IntroBlock css={S.clipBox} title={title} intro={intro} />
      </S.PageBody>
      <Steps steps={steps} />
      <GetStarted {...getStarted1} />
      <Timeline phases={timeline} />
      <Countdown phases={timeline} />
      <StatList {...whoCanEnter} />
      <HeroBar {...awards} />
      <PastWinners {...pastWinners} />
      <HeroBar {...getStarted2} />
    </article>
  </Layout>
);

export default HowToCompete;

export const howToCompeteFragment = graphql`
  fragment HowToCompetePage on ContentJson {
    ...SEO
    title
    intro
    ...Steps
    getStarted1 {
      ...HeroFields
    }
    awards {
      title
      text
    }
    ...PastWinners
    ...WhoCanEnter
    getStarted2 {
      ...HeroCTA
    }
  }
`;

export const howToCompeteFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "markdown",
  },
  stepsField,
  {
    ...getStartedField,
    label: "CTA Module 1",
    name: "getStarted1",
  },
  timelineField,
  whoCanEnterField,
  {
    label: "Awards",
    name: "awards",
    widget: "object",
    fields: [
      {
        label: "Title",
        name: "title",
        widget: "string",
      },
      {
        label: "Text",
        name: "text",
        widget: "string",
      },
    ],
  },
  pastWinnersField,
  {
    ...heroBarField,
    label: "CTA Module 2",
    name: "getStarted2",
  },
];
