import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { rem } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { H1, Container, innerContainer } from "../styles/shared";

const gutter = 40;

export const StatBox = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: ${rem(60)} auto;

  @media (${desktop}) {
    margin: ${rem(120)} auto;
  }
`;

export const StatHeadline = styled(H1)`
  ${innerContainer};
  margin-top: 0;
  margin-bottom: ${rem(30)};
`;

export const StatList = styled.section`
  @media (${desktop}) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: ${rem(gutter * -1)};
    margin-right: ${rem(gutter * -1)};
  }
`;

export const StatLI = styled.div`
  @media (${desktop}) {
    width: calc(50% - ${rem(gutter * 2)});
    margin: 0 ${rem(gutter)};
  }
`;
