import React, { useState } from "react";
import _get from "lodash.get";
import { useInterval } from "react-use";

import AnchorLink from "app/components/anchor-link";
import ShareBtn from "app/components/share-button";
import SiteData from "app/content/_site-settings.json";

import { useActivePhase } from "app/hooks/use-timeline-data";

import * as S from "app/components/countdown.styles";

const SS = 1000;
const MM = 60 * SS;
const HH = 60 * MM;
const DD = 24 * HH;

const leftpad = (count) => ("00" + count).substring(count.length);

const days = (time) => Math.floor(time / DD);
const hours = (time) => Math.floor((time % DD) / HH);
const mins = (time) => Math.floor((time % HH) / MM);
const secs = (time) => Math.floor((time % MM) / SS);

const ClockTime = ({ time, length, unit }) => (
  <S.CDUnit>
    <S.CDNum length={length}>{length ? leftpad("" + time) : time}</S.CDNum>
    {unit}
  </S.CDUnit>
);

const useCountdown = (deadline) => {
  const now = Date.now();
  const [count, setCount] = React.useState(deadline - now);
  const active = !!deadline && deadline >= now;

  useInterval(
    () => {
      setCount(deadline - Date.now());
    },
    !!active ? 1000 : null
  );

  return active ? count : Date.now();
};

const CountdownLive = ({ deadline }) => {
  const timer = useCountdown(deadline);

  return (
    <S.CDTime>
      <ClockTime time={days(timer)} unit="d" />
      <ClockTime length={2} time={hours(timer)} unit="h" />
      <ClockTime length={2} time={mins(timer)} unit="m" />
      <ClockTime length={2} time={secs(timer)} unit="s" />
    </S.CDTime>
  );
};

const CountdownStatic = () => (
  <S.CDTime>
    <ClockTime time={0} unit="d" />
    <ClockTime length={2} time={0} unit="h" />
    <ClockTime length={2} time={0} unit="m" />
    <ClockTime length={2} time={0} unit="s" />
  </S.CDTime>
);

const CountdownShare = ({ title, date, daysLeft, text }) => {
  const siteUrl = _get(SiteData, "siteMeta.url");
  const dateJs = new Date(date);
  const month = dateJs.toLocaleString("default", { month: "long" });
  const shareMsg = `${month} ${dateJs.getDate()}. ${text} ${siteUrl}`;

  const shareConfig = {
    url: siteUrl,
    description: shareMsg,
    title: `${title} ${daysLeft}days left! ${shareMsg}`,
    message: shareMsg,
    subject: title,
  };

  return <ShareBtn icon={false} invert config={shareConfig} />;
};

const CountdownTimer = (props) => {
  const [daysLeft, setDaysLeft] = useState(0);
  const activePhase = useActivePhase();
  const title = _get(activePhase, "title") || "Time's up!";
  const today = _get(SiteData, "contest.cms_today")
    ? new Date(_get(SiteData, "contest.cms_today"))
    : Date.now();
  const deadline = new Date(_get(activePhase, "date"));
  const countdown = deadline > today;

  return (
    <div>
      <AnchorLink name="countdown-timer" />
      <S.CDBox as="section">
        <S.CDContent>
          <S.CDTtle>{title}</S.CDTtle>
          {!countdown && <CountdownStatic />}
          {countdown && (
            <CountdownLive setDaysLeft={setDaysLeft} deadline={deadline} />
          )}
          <CountdownShare title={title} daysLeft={daysLeft} {...props} />
        </S.CDContent>
      </S.CDBox>
    </div>
  );
};

export default CountdownTimer;
