import React from "react";
import { useEndDate } from "app/hooks/use-end-date";

import { btnField } from "app/components/btn";
import Hero from "app/components/hero";

import getStartedBg from "../../static/videos/get_started_banner_hq.mp4";

const GetStarted = (props) => {
  const { contestOver } = useEndDate();
  return contestOver ? null : <Hero {...props} isDark videoBg={getStartedBg} />;
};

export default GetStarted;

export const getStartedField = {
  label: "Hero",
  name: "hero",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    btnField,
  ],
};
