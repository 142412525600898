import React from "react";
import { graphql } from "gatsby";

import video1 from "../../static/videos/how_to_compete.mp4";
import video2 from "../../static/videos/innovative_ideas.mp4";
import video3 from "../../static/videos/submit_your_ideas.mp4";

import * as S from "./steps.styles";
import Btn, { btnField } from "./btn";

const videoBGs = [video1, video2, video3];

const StepIcon = ({ count, reverse }) => (
  <S.StepIcon>
    <S.StepCountTxt reverse={reverse}>{count}</S.StepCountTxt>
    <S.StepVideo circle src={videoBGs[count - 1]} />
  </S.StepIcon>
);

const Step = ({ title, desc, img, idx, odd }) => (
  <S.StepWrap as={"li"} idx={idx}>
    <S.StepItem odd={odd}>
      <S.StepCount pad odd={odd}>
        <StepIcon img={img} count={idx + 1} reverse={odd} />
      </S.StepCount>
      <S.StepContent odd={odd}>
        <S.StepTitle as="h2">{title}</S.StepTitle>
        <S.StepText as="p">{desc}</S.StepText>
      </S.StepContent>
    </S.StepItem>
  </S.StepWrap>
);

const Steps = ({ steps }) => {
  if (!Array.isArray(steps) || !steps.length) {
    return null;
  }

  return (
    <S.StepList as={"ol"}>
      {steps.map((step, idx) => (
        <Step key={idx} idx={idx} odd={idx % 2} {...step} as="li" />
      ))}
    </S.StepList>
  );
};

Steps.defaultProps = {
  steps: [],
};

export const stepsField = {
  label: "Steps",
  name: "steps",
  widget: "list",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Desc",
      name: "desc",
      widget: "text",
      required: false,
    },
  ],
};

export default Steps;

export const StepHero = ({ title, text, btn, steps }) => (
  <S.StepHeroBox as="section">
    <S.StepHeroContent>
      <S.StepHeroTitle as="h2">{title}</S.StepHeroTitle>
      <S.StepHeroText as="p">{text}</S.StepHeroText>
      <S.StepFlatList as={"ol"}>
        {Array.isArray(steps) &&
          steps.map(({ img, title }, idx) => (
            <S.StepFlatLI key={`step-${idx}`}>
              <StepIcon key={`step-${idx}`} img={img} count={idx + 1} />
              <S.StepTitle as="span">{title}</S.StepTitle>
            </S.StepFlatLI>
          ))}
      </S.StepFlatList>
      <Btn css={S.stepHeroBtn} {...btn} />
    </S.StepHeroContent>
  </S.StepHeroBox>
);

export const stepsFragment = graphql`
  fragment HowToCompete on ContentJson {
    howToCompete {
      title
      text
      steps {
        title
        desc
      }
      btn {
        ...ButtonFields
      }
    }
  }
  fragment Steps on ContentJson {
    steps {
      title
      desc
    }
  }
`;

export const stepHeroField = {
  label: "How to Compete",
  name: "howToCompete",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Text",
      name: "text",
      widget: "text",
      required: false,
    },
    stepsField,
    {
      ...btnField,
      label: "CTA",
    },
  ],
};
