import _get from "lodash.get";

import SiteData from "app/content/_site-settings.json";

export const convertToTimestamp = (value) => {
  const testDate = new Date(value);
  const date =
    testDate instanceof Date && !isNaN(testDate) ? testDate : new Date();

  return date.toISOString();
};

export const useEndDate = () => {
  const end_date = _get(SiteData, "contest.end_date");
  const cms_today = _get(SiteData, "contest.cms_today");

  const endDate = convertToTimestamp(end_date);
  const today = convertToTimestamp(cms_today);

  return { endDate, today, contestOver: today >= endDate };
};

export default useEndDate;

export const contestField = {
  label: "Contest",
  name: "contest",
  widget: "object",
  fields: [
    {
      label: "Contest End Date",
      name: "end_date",
      widget: "datetimeLocal",
    },
    {
      label: "Today",
      name: "today",
      widget: "datetimeLocal",
      hint: "This is used for QA testing. Leave this blank to use the current date.",
      required: false,
      default: "",
    },
  ],
};
