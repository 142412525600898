import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { H1, Container, centerTxt, mobileGutter } from "../styles/shared";

const { black, white } = colors;

const size = 200;
const height = 140;
const timelineW = rem(200 * 5);

const inactive = css`
  position: absolute;
  visibility: hidden;
`;

export const hideInactive = ({ active }) => !active && inactive;

export const minHeight = ({ minH = 0 }) => css`
  min-height: ${rem(minH)};
`;

export const TlnBox = styled(Container)`
  position: relative;
  padding: ${rem(60)} 0 ${rem(40)};
  overflow: hidden;

  @media (${desktop}) {
    padding: ${rem(60)} 0 ${rem(90)};
  }
`;

export const TlnTitle = styled(H1)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${centerTxt};
  margin: 0 auto ${rem(50)};
  ${hideInactive}
  ${minHeight};
  ${mobileGutter};
`;

export const TlnCrlViewbox = styled(Container)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${rem(height)};
  padding: 0;
`;

export const TlnWrap = styled.div`
  position: relative;
  width: ${timelineW};

  @media (max-width: 1000px) {
    width: ${rem(200)};
  }
`;

const positionTimeline = ({ active, delta }) => css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: ${delta ? `none` : `transform 0.2s linear`};

  @media (max-width: 1000px) {
    left: 0;
    transform: translateX(${rem(active * -200 - delta)}) translateZ(0);
  }
`;

export const TlnCrlList = styled.ol`
  ${positionTimeline};
  display: flex;
  justify-content: center;
  overflow: visible;
  width: ${timelineW};
  list-style: none;
  padding: 0;
  margin: 0 auto;
`;

const positionMarker = ({ active }) => css`
  transform: translate3d(${rem(active * 200)}, -50%, 0);
`;

export const ActiveMarker = styled.div`
  position: absolute;
  width: ${rem(size)};
  height: ${rem(height)};
  overflow: hidden;
  top: 50%;
  left: 0;
  ${positionMarker};
  transition: transform 0.21s linear;
  /* border: 1px solid red; */

  @media (max-width: 1000px) {
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const activeBg = css`
  position: absolute;
  width: ${rem(height)};
  height: ${rem(height)};
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const TlnCrlItem = styled.li`
  position: relative;
  margin: 0;
  padding: 0;
`;

export const TlnBar = ({ first, last }) => css`
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${(first && "50%") || 0};
    display: block;
    width: ${((first || last) && "55%") || "100%"};
    height: ${rem(10)};
    background: ${black};
    content: "";
    z-index: -1;
  }
`;

export const TlnBtnWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(size)};
  height: ${rem(height)};
  overflow: visible;
  ${TlnBar};
  /* border: 1px solid green; */
`;

export const TlnBtn = styled.button`
  position: relative;
  display: flex;
  width: ${rem(88)};
  height: ${rem(88)};
  align-items: center;
  justify-content: center;
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.bold};
  text-align: center;
  background-color: ${black};
  border: 0;
  color: ${white};
  border-radius: 100%;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 2;
`;

export const TlnCard = styled.div`
  max-width: ${rem(360)};
  margin: ${rem(20)} auto;
  text-align: center;
  ${hideInactive}
  ${minHeight};
`;

export const TlnCardDate = styled.span`
  display: block;
  margin-top: ${rem(50)};
  margin-bottom: ${rem(30)};
  ${fontSize(40, 51)};
  font-weight: ${fontWeights.black};
`;

export const TlnCardTxt = styled.p`
  ${fontSize(20, 30)};
  font-weight: ${fontWeights.book};
`;
