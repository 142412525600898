import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Container } from "../styles/shared";

const { black, white, darkGray } = colors;

export const CDBox = styled.div`
  padding: ${rem(80)} 0 ${rem(60)};
  background-color: ${black};
  color: ${white};
`;

export const CDContent = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CDTtle = styled.h2`
  margin-bottom: ${rem(30)};
  ${fontSize(20, 28)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;

  @media (${desktop}) {
    margin-bottom: ${rem(70)};
  }
`;

export const CDTime = styled.p`
  margin: 0 0 ${rem(45)};
  ${fontSize(40, 50)};
  font-weight: ${fontWeights.book};
  color: ${darkGray};

  @media (${desktop}) {
    ${fontSize(70, 70)};
    margin: 0 0 ${rem(90)};
  }
`;

export const CDUnit = styled.span`
  display: inline-block;
  margin: 0 ${rem(2)};

  &:last-of-type {
    margin-right: ${rem(-20)};
  }

  &:first-of-type {
    margin-left: ${rem(-20)};
  }

  @media (${desktop}) {
    margin: 0 ${rem(5)};
  }
`;

export const CDNum = styled.span`
  display: inline-block;
  width: ${({ length: l }) => (l === 2 && rem(50)) || "auto"};
  font-weight: ${fontWeights.bold};
  color: ${white};

  @media (${desktop}) {
    width: ${({ length: l }) => (l === 2 && rem(85)) || "auto"};
  }
`;
