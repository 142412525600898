import React from "react";
import { graphql } from "gatsby";

import Tile from "app/components/tile";

import { btnField } from "app/components/btn";
import video from "../../static/videos/timeline.mp4";

import * as S from "app/components/stat-list.styles";

const startTimes = [0, 3, 6, 9];

const cycle = (idx) => startTimes[idx % startTimes.length];

const StatList = ({ headline, stats }) => (
  <S.StatBox as="section">
    <S.StatHeadline>{headline}</S.StatHeadline>
    {stats.length > 0 && (
      <S.StatList>
        {stats.map((stat, idx) => (
          <S.StatLI key={idx}>
            <Tile
              imgSize={"xsmall"}
              videoSrc={`${video}#t=${cycle(idx)}`}
              {...stat}
            />
          </S.StatLI>
        ))}
      </S.StatList>
    )}
  </S.StatBox>
);

StatList.defaultProps = {
  stats: [],
};

export default StatList;

export const whoCanEnterFragment = graphql`
  fragment WhoCanEnter on ContentJson {
    whoCanEnter {
      headline: title
      stats: rules {
        text
        title
        cta {
          ...ButtonFields
        }
      }
    }
  }
`;

export const whoCanEnterField = {
  label: "Who can Enter",
  name: "whoCanEnter",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Rules",
      name: "rules",
      widget: "list",
      allow_add: false,
      fields: [
        {
          label: "Title",
          name: "title",
          widget: "string",
        },
        {
          label: "Desc",
          name: "text",
          widget: "text",
        },
        {
          ...btnField,
          name: "cta",
        },
      ],
    },
  ],
};
